import XLSX from "xlsx-js-style";
export class Excel {
    constructor(){
        this.wb = XLSX.utils.book_new();
    }

    adicionarSheet(tabela, nomeSheet = "Sem nome"){
        let ws = null;
        let merges = [];
        let r = 0, c = 0;
        for (let TR of tabela.getElementsByTagName('tr')) {
            let row = []
            c = 0;
            for (let TD of TR.getElementsByTagName('td')) {
                let colorFill = TD.style == null ? null : TD.style.backgroundColor != "" ? this.rgbToHex(TD.style.backgroundColor) : null;
                let textHorizontalAlign = TD.style == null ? "left" : TD.style.textAlign != "" ? TD.style.textAlign : "left";
                let fontBold = TD.style == null ? false : TD.style.fontWeight == "bold";
                let border = null;
                if(TD.style){
                    border = {
                        top : (TD.style.borderTop ? {
                            style : "thin",
                            color : {rgb: this.rgbToHex(TD.style.borderTopColor)}
                        } : null),
                        left : (TD.style.borderLeft ? {
                            style : "thin",
                            color : {rgb: this.rgbToHex(TD.style.borderLeftColor)}
                        } : null),
                        right : (TD.style.borderRight ? {
                            style : "thin",
                            color : {rgb: this.rgbToHex(TD.style.borderRightColor)}
                        } : null),
                        bottom : (TD.style.borderBottom ? {
                            style : "thin",
                            color : {rgb: this.rgbToHex(TD.style.borderBottomColor)}
                        } : null),
                        
                    }
                }
                
                let style = {
                    fill: (colorFill ? { fgColor: { rgb: colorFill } } : null),
                    alignment: {
                        horizontal : textHorizontalAlign,
                        wrapText : true,
                    },
                    font: { 
                        bold: fontBold, 
                        /*color: { rgb: "FF0000" }*/ 
                    },

                    border : border
                }

                row.push({
                    v : TD.innerText || TD.textContent,
                    t : "s",
                    s: style
                })

                let colspan = TD.colSpan || TD.getAttribute('colspan');
                for(let i = 0; i < colspan - 1; i++){
                    row.push({
                        v : '',
                        t : "s",
                        s: style
                    })
                }
                let rowspan = TD.rowSpan || TD.getAttribute('rowspan');
                if(rowspan > 1){
                    merges.push({ s: { r: r, c: c }, e: { r: r + rowspan - 1, c: c } });
                }
                if(colspan > 1){
                    merges.push({ s: { r: r, c: c }, e: { r: r, c: (c + (colspan - 1) ) } });
                    c += (colspan - 1);
                }
                c++;
            }
            r++;
            
            if(ws == null){
                ws = XLSX.utils.aoa_to_sheet([row]);
            }else{
                XLSX.utils.sheet_add_aoa(ws, [row], {origin: -1});
            }
        }

        let wscols = [];

        for(let col of tabela.getElementsByTagName('col')){
            wscols.push({wch:col.width})
        }
        
        ws['!cols'] = wscols
        ws["!merges"] = merges;
        XLSX.utils.book_append_sheet(this.wb, ws, nomeSheet);
    }

    baixarExcel(nomeArquivo = "Arquivo.xlsx"){
        XLSX.writeFile(this.wb, nomeArquivo);
    }

    componentToHex(c) {
        var hex = parseInt(c).toString(16).toUpperCase();
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(rgb) {
        rgb = rgb.replace('rgb(','').replace(')','').split(',');
        let r = rgb[0].trim();
        let g = rgb[1].trim();
        let b = rgb[2].trim();
        return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
}